import Head from 'next/head'
import { createClient } from 'services/prismic'

import Divider from 'components/Divider'
import PrismicPage from 'components/PrismicPage'
import Spacer from 'components/Spacer'

export default function Index({ document }) {
  return (
    <>
      <Head>
        <title>Watches - The Armoury</title>
      </Head>
      <Spacer y="insetY" />
      <PrismicPage document={document} />
      <Spacer y="insetY" />
      <Divider />
    </>
  )
}

export async function getServerSideProps({ previewData }) {
  const prismic = createClient({ previewData })
  const document = await prismic.getPage('watches')

  return {
    props: {
      document,
    },
  }
}
